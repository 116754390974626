export default function TextAnimation() {
	return (
		<div className="text-animation-container">
			<span>C</span>
			<span>o</span>
			<span>f</span>
			<span>f</span>
			<span>e</span>
			<span>e</span>
			<span>F</span>
			<span>i</span>
			<span>x</span>
		</div>
	);
}
